import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { atomWithQuery } from "jotai/query";
import * as jwt from "jsonwebtoken";

type EngagementMedium = "POPUP" | "EMBED";

export const REFERRAL_PROGRAM = "21479";
export const INSTANT_ACCESS_PROGRAM = "21552";

export type User =
  | {
      shaEmail: string;
      email: string;
      firstName?: string;
      lastName?: string;
    }
  | undefined;

export const LoggedInUser = atomWithStorage<User>("klipUser", undefined);

export const registerAtom = atom(false);

//@ts-ignore
export const UserName = atomWithQuery((get) => {
  const loggedInUser = get(LoggedInUser);
  const widgetIdent = get(WidgetIdent);
  if (!loggedInUser || !widgetIdent?.jwt)
    return { firstName: "", lastName: "", firstInitial: "" };

  return {
    queryKey: "getUserName",
    queryFn: async () => {
      const response = await fetch(
        `${process?.env.REACT_APP_DOMAIN}/api/v1/${process?.env.REACT_APP_TENANT_ALIAS}/open/account/${loggedInUser.shaEmail}/user/${loggedInUser.shaEmail}`,
        {
          headers: {
            Authorization:
              "Basic " +
              btoa(
                `${process?.env.REACT_APP_API_ID}:${process?.env.REACT_APP_API_SECRET}`
              ),
            "Content-Type": "application/json",
          },
        }
      );
      const { firstName, lastName } = await response.json();
      return {
        firstName,
        lastName,
        firstInitial: firstName?.charAt(0).toLocaleUpperCase(),
      };
    },
  };
});

export const WidgetIdent = atom((get) => {
  const loggedInUser = get(LoggedInUser);
  const locale = get(LocaleAtom);
  const comeFromRegister = get(registerAtom);
  if (!loggedInUser) return;
  const { email, firstName, lastName, shaEmail } = loggedInUser;

  let user = {
    accountId: shaEmail,
    id: shaEmail,
    email: email,
    firstName,
    lastName,
    locale,
    customFields: {},
  };

  if (comeFromRegister) {
    user = { ...user, customFields: { signupType: "freeTrial" } };
  }

  const token = jwt.sign(
    {
      user,
    },
    //@ts-ignore
    process?.env.REACT_APP_API_SECRET,
    {
      noTimestamp: true,
      header: {
        kid: process?.env.REACT_APP_API_ID,
      },
    }
  );

  window.ire("identify", {
    customerid: email,
    customeremail: shaEmail,
    campaignId: 21479,
  });

  window.impactToken = token;

  return {
    engagementMedium: "EMBED" as EngagementMedium | undefined,
    appDomain: process?.env.REACT_APP_DOMAIN,
    tenantAlias: process?.env.REACT_APP_TENANT_ALIAS,
    programId: REFERRAL_PROGRAM,
    widgetType: `p/${REFERRAL_PROGRAM}/w/referrerWidget`,
    id: shaEmail,
    accountId: shaEmail,
    email: email,
    firstName,
    lastName,
    user,
    jwt: token,
  };
});

export const IsLoggedIn = atom((get) => (get(LoggedInUser) ? true : false));

export const LocaleAtom = atomWithStorage("localePreference", "en");

export const WidgetAtom = atom(`p/${REFERRAL_PROGRAM}/w/referrerWidget`);
